<template>
  <v-dialog @input="close" :value="show" width="800px" persistent>
    <v-card>
      <v-card-title style="background-color:#F44336">
        <div>
          <v-btn small absolute top right icon @click="cancel">
            <v-icon style="color: #ffffff">close</v-icon>
          </v-btn>
        </div>
        <v-col class="pa-0" cols="12">
          <h3 style="color: #ffffff">
            Atenção
          </h3>
        </v-col>
      </v-card-title>
      <v-card-text class="mt-5">
        <div>
          <p class="text--primary font-weight-regular">
            <slot>
              Esta ação irá apagar este item
            </slot>
          </p>
          <p class="text--primary font-weight-regular">
            Tem Certeza que deseja continuar?
          </p>
        </div>
        <v-row class="mt-10">
          <v-btn text @click="cancel">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="#F44336" outlined :loading="loading" @click="deleted()">
            Confirmar
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      show: 'dialogs/delete',
      idDelete: 'dialogs/idDelete',
    }),
  },
  methods: {
    deleted() {
      this.loading = true
      this.$emit('delete', { status: true, id: this.idDelete })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 2000)
    },
    cancel() {
      this.close()
      this.$emit('delete', { status: false, id: this.idDelete })
    },
    close() {
      this.$store.commit('dialogs/setDelete', { status: false, id: null })
    },
  },
}
</script>

<style lang="sass" scoped>
.title
  background-color: red
  color: white
</style>
