<template>
  <div>
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.smAndUp ? '75vw' : '90vw'"
      persistent
    >
      <v-card v-if="dialog">
        <v-card-title>
          <div>
            <v-btn small absolute top right icon @click="dialog = !dialog">
              <v-icon>close</v-icon>
            </v-btn>
          </div>
          <v-col class="pl-0" cols="12">
            <h3>
              Fornecedores
            </h3>
          </v-col>
        </v-card-title>
        <v-card-text>
          <v-row
            justify="space-between"
            align="center"
            no-gutters
            class="ma-1 mb-2"
          >
            <v-col cols="12" lg="6" md="6" sm="5">
              <v-text-field
                label="Busque pelo Nome do fornecedor"
                v-model="nameProvider"
                required
              ></v-text-field>
            </v-col>
            <v-btn color="#1565C0" outlined @click="openFormProvider({})">
              Inserir Fornecedor
            </v-btn>
          </v-row>
          <v-data-table
            no-data-text="Não há fornecedores"
            :loading="loading"
            loading-text="Carregando informações"
            :headers="$vuetify.breakpoint.smOnly ? headersMobile : headers"
            :items="providers"
            :options="pagination"
            :search="search"
            class="elevation-1"
            :disable-sort="$vuetify.breakpoint.smAndDown"
            :footer-props="{
              itemsPerPageOptions: [5, 10],
              itemsPerPageText: 'Linhas por página',
            }"
          >
            <template v-slot:item.foreign="{ item }">
              {{ item.foreign ? 'Sim' : 'Não' }}
            </template>
            <template v-slot:item.phone="{ item }">
              {{ item.phone ? maskPhone(item.phone) : '--' }}
            </template>
            <template v-slot:item.email="{ item }">
              {{ item.email ? item.email : '--' }}
            </template>
            <template v-slot:item.cpfCnpj="{ item }">
              {{ item.cpfCnpj ? item.cpfCnpj : '--' }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn color="success" icon @click="openFormProvider(item)"
                ><v-icon color="grey darken-2">mode_edit</v-icon></v-btn
              >
              <v-btn color="success" icon @click="deactivateProvider(item)"
                ><v-icon color="grey darken-2">delete_forever</v-icon></v-btn
              >
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <formProvider
      v-model="insertProvider"
      v-if="insertProvider"
      :editProvider="itemProvider"
    ></formProvider>
    <deactivateProvider
      v-model="openDeactivateProvider"
      v-if="openDeactivateProvider"
      :deactivateProvider="itemDeactivateProvider"
    ></deactivateProvider>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import formProvider from './formProvider.vue'
import deactivateProvider from './deactivateProvider.vue'

export default {
  components: {
    formProvider,
    deactivateProvider,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    ...mapGetters({
      providers: 'providers/listProvidersActive',
    }),
  },
  data() {
    return {
      search: '',
      nameProvider: '',
      loading: false,
      itemProvider: {},
      insertProvider: false,
      openDeactivateProvider: false,
      itemDeactivateProvider: {},
      pagination: {
        itemsPerPage: 5,
        sortBy: ['name'],
      },
      headers: [
        {
          text: 'Nome',
          value: 'name',
          width: '10%',
          filter: (value) => {
            return value.toLowerCase().includes(this.nameProvider.toLowerCase())
          },
        },
        {
          text: 'CPF/CNPJ',
          value: 'cpfCnpj',
          width: '5%',
        },
        {
          text: 'Telefone',
          value: 'phone',
          width: '10%',
        },
        {
          text: 'E-mail',
          value: 'email',
          width: '5%',
        },
        {
          text: 'Estrangeiro',
          value: 'foreign',
          width: '2%',
          align: 'center',
        },
        {
          text: 'Ações',
          value: 'actions',
          align: 'center',
          width: '5%',
        },
      ],
      headersMobile: [
        {
          text: 'Nome',
          value: 'name',
          width: '5%',
        },
        {
          text: 'CPF/CNPJ',
          value: 'cpfCnpj',
          width: '5%',
        },
        {
          text: 'Telefone',
          value: 'phone',
          width: '15%',
        },
        {
          text: 'Ações',
          value: 'actions',
          align: 'center',
          width: '2%',
        },
      ],
    }
  },
  methods: {
    openFormProvider(item) {
      this.insertProvider = true
      this.itemProvider = item
    },
    deactivateProvider(item) {
      this.openDeactivateProvider = true
      this.itemDeactivateProvider = item
    },
    maskPhone(phone) {
      phone = phone.replace(/^(\d\d)(\d)/g, '($1) $2')

      if (phone.length < 14) phone = phone.replace(/(\d{4})(\d)/, '$1-$2')
      else phone = phone.replace(/(\d{5})(\d)/, '$1-$2')

      return phone
    },
  },

  watch: {
    providers(newValue, oldValue) {
      if (newValue) {
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 2000)
      }
    },
  },
}
</script>
