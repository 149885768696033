<template>
  <v-row no-gutters>
    <v-col :cols="$vuetify.breakpoint.smAndDown ? '11' : '5'" class="mr-4">
      <v-select
        v-model="monthLocal"
        :items="monthList"
        v-bind="$attrs.select || null"
        item-text="name"
        item-value="value"
        multiple
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @click="toggle">
            <v-list-item-action style="margin-right:10px">
              <v-icon :color="isAllMonthSelect ? 'info' : ''">
                {{ isAllMonthSelect ? 'check_box' : 'indeterminate_check_box' }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              Selecionar todos
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>

        <template v-slot:selection="{ item, index }">
          <span v-if="isAllMonthSelect && index === 0">
            Todos
          </span>
          <span
            v-if="!isAllMonthSelect && index === 0 && monthLocal.length >= 3"
            class="grey--text caption"
          >
            {{ item.name }} (+{{ monthLocal.length - 1 }})
          </span>
          <span v-if="!isAllMonthSelect && monthLocal.length < 3"
            >{{ item.name }}{{ monthLocal.length > 1 ? ',  ' : '' }}</span
          >
        </template>
      </v-select>
    </v-col>
    <v-col :cols="$vuetify.breakpoint.smAndDown ? '11' : '5'">
      <input-date
        v-model="year"
        :input="{ label: labelYear }"
        @input="setYearMonth($event, monthLocal)"
      ></input-date>
    </v-col>
  </v-row>
</template>

<script>
import inputDate from './inputDateYear.vue'
export default {
  name: 'select-month',

  components: {
    inputDate,
  },
  props: {
    value: {
      type: Array,

      required: false,
    },
    labelYear: {
      type: String,
      default: 'Ano de Vencimento',
      required: false,
    },
    yearMonth: {
      type: Array,
      required: true,
      default: [],
    },
  },
  data() {
    return {
      year: this.$moment()
        .year()
        .toString(),
      monthList: [
        {
          name: 'Janeiro',
          value: '01',
        },
        {
          name: 'Fevereiro',
          value: '02',
        },
        {
          name: 'Março',
          value: '03',
        },
        {
          name: 'Abril',
          value: '04',
        },
        {
          name: 'Maio',
          value: '05',
        },
        {
          name: 'Junho',
          value: '06',
        },
        {
          name: 'Julho',
          value: '07',
        },
        {
          name: 'Agosto',
          value: '08',
        },
        {
          name: 'Setembro',
          value: '09',
        },
        {
          name: 'Outubro',
          value: '10',
        },
        {
          name: 'Novembro',
          value: '11',
        },
        {
          name: 'Dezembro',
          value: '12',
        },
      ],
    }
  },
  computed: {
    monthLocal: {
      get: function() {
        if (!this.yearMonth || this.yearMonth.length === 0) return []
        let temp = this.yearMonth.map((item) => {
          let [year, month] = item.split('-')
          return month || ''
        })
        return temp
      },
      set: function(value) {
        this.setYearMonth(this.year, value)
      },
    },
    isAllMonthSelect() {
      return this.monthLocal.length === this.monthList.length
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.isAllMonthSelect) {
          this.monthLocal = !!this.month ? [`${this.month}`] : []
        } else {
          this.monthLocal = this.monthList.map((item) => {
            return `${item.value}`
          })
        }
      })
    },
    selectMonth() {
      this.monthLocal = [`${this.month}`]
    },
    setYearMonth(newYear, newMonths) {
      let temp = newMonths.map((item) => {
        let month = item
        let year = newYear
        if (item.includes('-')) {
          ;[year, month] = item.split('-')
        }

        return `${year}-${month}`
      })
      this.$emit('update:yearMonth', temp)
    },
  },
}
</script>

<style scoped></style>
