<template>
  <div>
    <v-dialog v-model="dialog" width="800px" persistent>
      <v-card v-if="dialog">
        <v-card-title style="background-color:#F44336">
          <div>
            <v-btn small absolute top right icon @click="dialog = !dialog">
              <v-icon style="color: #ffffff">close</v-icon>
            </v-btn>
          </div>
          <v-col class="pa-0" cols="12">
            <h3 style="color: #ffffff">
              Atenção
            </h3>
          </v-col>
        </v-card-title>
        <v-card-text class="mt-5">
          <div>
            <p class="text--primary font-weight-regular">
              Esta ação irá apagar o seguinte fornecedor:
            </p>
            <p class="text--primary">
              Nome:
              <span class="font-weight-bold">{{
                deactivateProvider.name
              }}</span>
              <br />
              CPF/CNPJ:
              <span class="font-weight-bold">{{
                deactivateProvider.cpfCnpj
              }}</span>
              <br />
              Telefone:
              <span class="font-weight-bold">{{
                deactivateProvider.phone ? deactivateProvider.phone : '--'
              }}</span>
              <br />
              E-mail:
              <span class="font-weight-bold">{{
                deactivateProvider.email ? deactivateProvider.email : '--'
              }}</span>
            </p>
            <p class="text--primary font-weight-regular">
              Tem Certeza que deseja continuar?
            </p>
          </div>
          <v-row class="mt-10">
            <v-btn text @click="dialog = false">
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              color="#F44336"
              outlined
              :loading="loading"
              @click="disableProvider()"
            >
              Confirmar
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    deactivateProvider: {
      type: Object,
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    disableProvider() {
      this.loading = true
      this.$store
        .dispatch('providers/disableProvider', this.deactivateProvider)
        .then(() => {
          this.$store.dispatch('providers/getListProviders')
          this.loading = false
          this.dialog = false
        })
    },
  },
}
</script>
