<template>
  <v-container fluid>
    <v-row>
      <v-subheader>Registrar Despesas</v-subheader>
      <v-card width="100%">
        <base-header-information :title="headerTitle" color="purple lighten-3">
          <template slot="description">
            <p>
              {{ headerDescription }}
            </p>
          </template>
        </base-header-information>
        <v-row class="px-5" align="center" justify="start">
          <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '5'">
            <p>Busque pelo mês e ano da despesa</p>
            <select-month
              :yearMonth.sync="form.month"
              :select="{ label: 'Mês da despesa' }"
              :labelYear="'Ano da despesa'"
            ></select-month>
          </v-col>
          <v-col :cols="$vuetify.breakpoint.smAndDown ? '12' : '3'">
            <p>Busque pelo categoria</p>
            <v-autocomplete
              :items="categoryAll"
              v-model="form.category"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="end" class="pr-5 mb-6">
          <div class="mb-2">
            <v-btn
              color="blue darken-3"
              class="white--text"
              @click="dialogsChangeNewExpense(true, {})"
            >
              Inserir nova despesa
              <v-icon right dark>add_circle</v-icon>
            </v-btn>
          </div>
          <div class="ml-2 mb-2">
            <v-btn
              color="blue darken-3"
              class="white--text"
              @click="showListProviders = true"
            >
              Fornecedores
            </v-btn>
          </div>
        </v-row>
        <v-data-table
          :loading="loading"
          loading-text="Carregando informações"
          :headers="headers"
          :items="filteredItems"
          :items-per-page.sync="pagination.itemsPerPage"
          :options="pagination"
          :disable-sort="$vuetify.breakpoint.smAndDown"
          data="date"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:item.dateReference="{ item }">
            {{ item.dateReference | moment('DD/MM/YYYY') }}
          </template>
          <template v-slot:item.category="{ item }">
            {{ item.category.name }}
          </template>
          <template v-slot:item.value="{ item }">
            {{ item.value | currencyFromCents }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon @click="dialogsEditExpense(item)">
              <v-icon color="grey darken-2">mode_edit</v-icon>
            </v-btn>
            <v-btn icon @click="openDialogDelete(item)">
              <v-icon color="grey darken-2">delete_forever</v-icon>
            </v-btn>
          </template>
          <template v-slot:no-data>
            <p class="my-5" style="color:#0288D1">
              <v-icon class="mb-1" color="#0288D1">info</v-icon> Não há
              Despesas.
            </p>
          </template>
          <template v-slot:no-results>
            <p class="my-5" style="color:#0288D1">
              <v-icon class="mb-1" color="#0288D1">info</v-icon> Não há
              Despesas.
            </p>
          </template>
        </v-data-table>
        <v-col cols="12" class="text-center mt-4">
          <v-btn
            color="info"
            @click="pagination.itemsPerPage += 6"
            v-if="showBtn"
          >
            Ver mais
          </v-btn>
        </v-col>
      </v-card>
    </v-row>
    <dialog-new-expense></dialog-new-expense>
    <listProviders
      v-model="showListProviders"
      v-if="showListProviders"
    ></listProviders>
    <dialog-deleted @delete="deleted">
      <p>{{ deteledText }}</p>
      <p class="text--primary">
        Categoria:
        <span class="font-weight-bold">{{ formDelete.category }}</span>
        <br />
        Data:
        <span class="font-weight-bold">{{ formDelete.date }} </span>
        <br />
        Descrição:
        <span class="font-weight-bold">{{ formDelete.description }}</span>
      </p>
    </dialog-deleted>
    <v-dialog v-model="viewDialogDeleteYearNow" width="500px" persistent>
      <v-card v-if="viewDialogDeleteYearNow">
        <v-card-title class="grey lighten-2">
          <v-col class="pa-0" cols="12">
            <h4>Ação inválida</h4>
          </v-col>
        </v-card-title>
        <v-card-text class="mt-5">
          <div>
            <p class="text--primary font-weight-regular">
              Essa despesa foi cadastrada com a seguinte data
              <span class="font-weight-bold">{{ formDelete.date }}</span
              >. <br />
              Não é possível deletar despesas de anos anteriores, é permitido
              apenas editá-las.
            </p>
          </div>
          <v-row class="mt-10 mr-1" justify="end">
            <v-btn outlined text @click="viewDialogDeleteYearNow = false">
              Ok
            </v-btn>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { months, getYears, strMonthToNumber } from '@src/services/date'
import dialogNewExpense from '@src/components/dialogs/dialog-new-expense.vue'
import dialogDeleted from '@src/components/widget/DialogDeleted.vue'
import selectMonth from '@src/components/input/selectMonth.vue'
import listProviders from '@src/components/provider/listProviders.vue'

export default {
  name: 'Expense',
  components: {
    dialogNewExpense,
    dialogDeleted,
    selectMonth,
    listProviders,
  },
  computed: {
    ...mapState({
      totalExpenses: (state) => state.expenses.totalValue,
      filteredItems() {
        this.loading = true
        setTimeout(() => {
          this.loading = false
        }, 1000)
        return this.expenses.filter((i) => {
          return (
            this.checkFilterValid(i.dateReference) &&
            this.checkFilterCategory(i)
          )
        })
      },
      categoryAll: (state) => [
        'Todas',
        ...state.expenses.category.data.map((item) => item.name),
      ],
    }),
    ...mapGetters({
      expenses: 'expenses/expenses',
    }),
    isNotMobol() {
      return this.$vuetify.breakpoint.mdAndUp
    },
    showBtn() {
      if (this.pagination.itemsPerPage >= this.filteredItems.length)
        return false
      return true
    },
  },
  data() {
    return {
      showListProviders: false,
      loading: false,
      pagination: {
        itemsPerPage: 5,
        sortBy: ['dateReference'],
      },
      search: null,
      headers: [
        {
          text: 'Data',
          align: 'left',
          value: 'dateReference',
        },
        {
          text: 'Descrição da despesa',
          value: 'description',
          sortable: false,
        },
        {
          text: 'Fornecedor',
          value: 'provider.name',
        },
        {
          text: 'Categoria',
          value: 'category',
          sortable: false,
        },
        {
          text: 'Valor',
          value: 'value',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '140px',
        },
      ],
      menu: false,
      deteledText: '',
      filters: {
        months: ['Todos', ...months],
        years: getYears('previuos', 15),
        categorys: [],
      },
      selectedMonth: [this.$moment().format('YYYY-MM')],
      form: {
        year: this.$moment().format('YYYY'),
        month: [this.$moment().format('YYYY-MM')],
        category: null,
      },
      formDelete: {
        date: null,
        category: null,
        description: null,
      },
      texAdditionalService: 'Solicitar nova Guia',
      headerTitle: 'Registrar Despesas',
      headerDescription: `
        Registre todas as suas despesas e mantenha a contabilidade da sua empresa sempre em dia!
        Este não é um procedimento obrigatório, porém, caso não registre nenhuma despesa,
        consideraremos apenas os seus gastos com  impostos (DAS e INSS) e com sua mensalidade contábil.
      `,
      yearNow: this.$moment()
        .hour(0)
        .minute(0)
        .second(0)
        .milliseconds(0)
        .format('Y'),
      viewDialogDeleteYearNow: false,
    }
  },
  methods: {
    ...mapActions({
      getExpenses: 'expenses/getExpenses',
      dialogsChangeNewExpense: 'dialogs/changeNewExpense',
      deletedExpense: 'expenses/deletedExpense',
      getExpenseCategory: 'expenses/getExpenseCategory',
    }),
    getColor(e) {
      switch (e.toLowerCase()) {
        case 'enviado':
          return 'primary lighten-1'
        default:
          return 'grey darken-2'
      }
    },

    dialogsEditExpense(item) {
      this.dialogsChangeNewExpense({
        status: true,
        expense: Object.assign({}, item),
      })
    },
    openDialogDelete(item) {
      this.formDelete.category = item.category.name
      this.formDelete.date = this.$moment(item.dateReference).format(
        'DD/MM/YYYY'
      )
      this.formDelete.description = item.description
      if (this.$moment(item.dateReference).format('YYYY') >= this.yearNow) {
        this.deteledText = 'Esta ação irá apagar a seguinte despesa:'
        this.$store.commit('dialogs/setDelete', { status: true, id: item.id })
      } else {
        this.viewDialogDeleteYearNow = true
      }
    },
    deleted({ status, id }) {
      if (status) {
        this.deletedExpense(id).then(() =>
          this.$store.commit('snackbarFlashMessage/setSuccessExclusion')
        )
      }
    },
    checkFilterValid(date) {
      let dateFormat = this.$moment(date).format('YYYY-MM')
      let detaCompleted = `${this.form.year}-${this.form.month}`
      return detaCompleted.includes(dateFormat)
    },
    checkFilterCategory: function(i) {
      return (
        this.form.category === 'Todas' || i.category.name == this.form.category
      )
    },
  },
  mounted() {
    this.$store.dispatch('providers/getListProviders')
    this.form.category = this.filters.categorys['Todos']
    this.getExpenses()
    this.form.category = 'Todas'
  },
}
</script>
<style lang="sass" scoped>
.cell-overflow
  white-space: nowrap

  width: 15rem

  overflow: hidden

  text-overflow: ellipsis
</style>
