<template>
  <div>
    <v-menu
      ref="menu"
      :close-on-content-click="false"
      v-model="menu"
      nudge-bottom
      offset-y
      transition="scale-transition"
      max-width="250px"
      min-width="90px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-bind="$attrs.input || null"
          v-on="on"
          v-model="valueLocal"
          readonly
        ></v-text-field>
      </template>
      <v-date-picker
        @input="save"
        ref="picker"
        reactive
        v-bind="$attrs.picker || null"
        v-model="valueLocal"
        no-title
        locale="pt-br"
        :show-current="$moment().format('YYYY-MM')"
      >
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'input-date',
  props: {
    value: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      menu: false,
    }
  },
  computed: {
    valueLocal: {
      get: function() {
        return this.value
      },
      set: function(value) {
        let formatYear = value.split('-')[0]
        this.$emit('input', formatYear)
      },
    },
  },
  methods: {
    save(valueLocal) {
      this.$refs.menu.save(valueLocal)
      this.$refs.picker.activePicker = 'YEAR'
      this.menu = false
    },
  },
  watch: {
    menu(val) {
      setTimeout(() => {
        if (val) {
          this.$nextTick(() => (this.$refs.picker.activePicker = 'YEAR'))
        }
      }, 50)
    },
  },
}
</script>

<style lang="scss" scoped></style>
